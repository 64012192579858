// 行业新闻
<template>
  <NewsList />
</template>

<script>
import NewsList from './components/NewsLists'
export default {
  components: {
    NewsList
  },
};
</script>

<style scoped lang="less">
</style>
